import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { Profile } from '../core/models/profile.model';
import { User } from '../core';

@Injectable({ providedIn: 'root' })
export class UserService extends ApiService {
  constructor(http: HttpClient) {
    super(http, 'User');
  }

  updateProfile(user: Profile) {
    return this.http.post(`${this.route()}/ProfileUpdate`, user);
  }

  getProfile<T>() {
    return this.http.get<T>(`${this.route()}/GetProfile`);
  }
}
