import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { catchError, of } from 'rxjs';
import { DropdownModel } from '../core';

@Injectable({ providedIn: 'root' })
export class NoteService extends ApiService {
  constructor(http: HttpClient) {
    super(http, 'Note');
  }

  public suggestEntity(term?: string) {
    const query = encodeURIComponent(term || '');
    if (!query) {
      return of([]);
    }
    const url = `${this.route()}/suggestEntity/${query}`;
    return this.http.get<DropdownModel[]>(url).pipe(
      catchError((error: HttpErrorResponse) => {
        if (error.status === 403) {
          return of([]);
        } else {
          throw error;
        }
      })
    );
  }
}
