import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { DropdownModel, DropdownProductQuoteModel } from '../core';
import { catchError, of,Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class ProductService extends ApiService {
  constructor(http: HttpClient) {
    super(http, 'Product');
  }


 public getProductWithCarrier(): Observable<DropdownProductQuoteModel[]> { 
    return this.http.options<DropdownProductQuoteModel[]>(this.route()+"/withCrrier").pipe(
      catchError((error: HttpErrorResponse) => {
        if (error.status === 403) {
          return of([]);
        } else {
          throw error;
        }
      })
    );
  }
}
