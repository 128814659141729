import { HttpClient } from '@angular/common/http';
import { Injectable, signal } from '@angular/core';
import { ApiService } from './api.service';
import { DropdownModel } from '../core';

@Injectable({ providedIn: 'root' })
export class SourceService extends ApiService {
  source = signal<DropdownModel[] | null>(null);
  
  constructor(http: HttpClient) {
    super(http, 'Source');
  }
}
