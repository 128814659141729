import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiService } from './api.service';

@Injectable({ providedIn: 'root' })
export class FacilityService extends ApiService {
  constructor(http: HttpClient) {
    super(http, 'Facility');
  }
}
