import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { DropdownModel } from '../core';
import { Observable, retry } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class UserFacilityService extends ApiService {
  constructor(http: HttpClient) {
    super(http, 'UserFacility');
  }

  public getUserFacilities(): Observable<DropdownModel[]> {
    const url = `${this.route()}/GetUserFacilities`;
    return this.http.get<DropdownModel[]>(url).pipe(retry(2));
  }
}
