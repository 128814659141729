import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { QuoteApproval } from '../core/models/quote-approval.model';
import { Quote } from '../core';

@Injectable({ providedIn: 'root' })
export class QuoteService extends ApiService {
  constructor(http: HttpClient) {
    super(http, 'Quote');
  }

  quoteApproval(data: QuoteApproval) {
    return this.http.post(`${this.route()}/quoteaproval`, data);
  }

  quoteView(quoteID: string) {
    return this.http.post<Quote>(`${this.route()}/QuoteView/${quoteID}`, null);
  }

}
