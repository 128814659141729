import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { ApiService } from './api.service';
import { Observable } from 'rxjs';
import { Location } from '@angular/common';

@Injectable({ providedIn: 'root' })
export class LogService extends ApiService {
  _location = inject(Location);

  constructor(http: HttpClient) {
    super(http, 'Log');
  }

  logNavigation(url: string): Observable<any> {
    const headers = new HttpHeaders().set('url-referrer', url);
    return this.http.get(this.route() + '/logNavigation', { headers });
  }
}
